<template>
  <div>
    <h2>
      {{ $t('onboarding.contractClosing.title') }}
    </h2>
    <p class="mt-5">
      {{ $t('onboarding.contractClosing.subtitle') }}
    </p>
    <p class="mt-5">
      {{ $t('onboarding.contractClosing.description') }}
    </p>
    <p>
      {{ $t('onboarding.contractClosing.subDescription') }}
    </p>
    <SignDocumentTemplate
      v-for="(docItem, index) in documents"
      :key="index"
      :documentKey="docItem"
      class="mt-10"
      :hideDocuments="docItem === 'onboarding_general'"
      :divider="docItem !== 'onboarding_general'"
      @accepted="makeDocAccepted($event, index)"
    />
    <v-card
      class="mx-auto my-10"
      color="transparent"
      elevation="0"
      max-width="760"
    >
      {{ $t('onboarding.contractClosing.footer') }}
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'Overview',
  components: {
    SignDocumentTemplate: lazyLoad('components/Documents/SignDocumentTemplate'),
  },
  data() {
    return {
      documents: [
        'onboarding_cometum',
        'onboarding_effecta',
        'onboarding_bvdh',
        'onboarding_general',
      ],
      docs: [
        false,
        false,
        false,
        false,
      ],
    };
  },
  created() {
    this.setFormValidity(false);
  },
  methods: {
    ...mapActions('onboarding', [
      'setFormValidity',
    ]),
    makeDocAccepted(event, index) {
      this.docs[index] = event;

      if (this.docs.every((doc) => doc)) {
        this.setFormValidity(true);
      } else {
        this.setFormValidity(false);
      }
    },
  },
};
</script>

<style>

</style>
